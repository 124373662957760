import React, { lazy } from 'react';
import './App.css';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import { Redirect } from "../components/Redirect/Redirect";
import {
    ROUTE_PRODUCT_CATEGORIES,
    ROUTE_PRODUCTS,
    ROUTE_RECIPE,
    ROUTE_RECIPES,
    ROUTE_SHOP_LIST,
    ROUTE_SHOP_LIST_MAIN,
    ROUTE_SIGN_IN,
    ROUTE_SIGN_UP,
} from '../constants/routes';
import 'react-toastify/dist/ReactToastify.css';

const SignIn = lazy(() => import('../pages/SignIn'));
const SignUp = lazy(() => import('../pages/SignUp'));
const ShopListMain = lazy(() => import('../pages/ShopListMain'));
const ShopList = lazy(() => import('../pages/ShopList'));
const Products = lazy(() => import('../pages/Products'));
const ProductCategories = lazy(() => import('../pages/ProductCategories'));
const Recipes = lazy(() => import('../pages/Recipes'));
const Recipe = lazy(() => import('../pages/Recipe'));

export function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path={ROUTE_SIGN_IN} element={<SignIn />} />
                <Route path={ROUTE_SIGN_UP} element={<SignUp />} />
                <Route path={ROUTE_SHOP_LIST_MAIN} element={<ShopListMain />} />
                <Route path={ROUTE_SHOP_LIST} element={<ShopList />} />
                <Route path={ROUTE_PRODUCTS} element={<Products />} />
                <Route path={ROUTE_PRODUCT_CATEGORIES} element={<ProductCategories />} />
                <Route path={ROUTE_RECIPES} element={<Recipes />} />
                <Route path={ROUTE_RECIPE} element={<Recipe />} />

                <Route path="/" element={<Redirect to={ROUTE_SHOP_LIST_MAIN} />} />
                <Route
                    path="*"
                    element={
                        <>
                            <h2>404 Page not found etc</h2>
                        </>
                    }
                />
            </Routes>
        </BrowserRouter>
    );
}
