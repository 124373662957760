import { Navigate, useLocation } from 'react-router-dom';
import { FC } from 'react';

interface RedirectProps {
  to: string;
}

export const Redirect: FC<RedirectProps> = ({ to }) => {
    const { search } = useLocation();

    return <Navigate to={`${to}${search}`} />;
};
